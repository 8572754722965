<template>
  <div class="table-wrapper">
    <table cellspacing="0">
      <tr class="header">
        <th v-for="header in headers" :key="header.id">{{ header.title }}</th>
      </tr>
      <tr v-for="item in items" :key="item.id">
        <td>
          {{ item.account_number || "----" }}
        </td>
        <td>
          {{ item.card_number }}
        </td>
        <td>
          {{ item.shaba_number }}
        </td>
        <td :style="{ color: handleStatus[item.status].color }">
          {{ handleStatus[item.status].name }}
        </td>
        <td>
          <img
            @click="onclick(item.id)"
            src="../../assets/images/fluent_delete-24-regular.svg"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    onclick: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      handleStatus: {
        process: { color: "#FB9224", name: "در حال بررسی" },
        confirm: { color: "#039100", name: "تایید شده" },
        reject: { color: "#DC0000", name: "رد شده" }
      }
    };
  }
};
</script>

<style scoped>
.table-wrapper {
  /*min-width: 1110px;*/
  height: 303px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*overflow: auto;*/
}

table {
  width: 100%;
  border: none;
  min-width: 1110px;
  /*height: 303px;*/
}

.header {
  background: rgba(31, 60, 136, 0.1);
  /*height: 45px;*/
}

th {
  padding: 10px 20px;
}

tr {
  /*height: 70px;*/
}

td {
  text-align: center;
  padding: 10px 0;
  /*height: 47px;*/
}

img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

img:hover {
  width: 25px;
  height: 25px;
}
</style>
