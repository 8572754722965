<template>
  <div id="bank-card">
    <loading :can-cancel="true"></loading>
    <panel-header title="کارتهای بانکی" />
    <div class="bank-card-container">
      <div class="add-card-form-container">
        <div class="add-card-form__header">
          افزودن کارت بانکی
        </div>
        <div class="add-card-form__wrapper">
          <!--          <GrayInput label="نام بانک" />-->
          <!--          <BankCardDropDownGrayInput-->
          <!--            required="true"-->
          <!--            ref="dropdown"-->
          <!--            @setValue="handlerValue"-->
          <!--          />-->
          <BankCardSelectWithImage
            :options="options"
            title="نام بانک"
            @input="getOptionItem"
          />
          <GrayInput
            v-model="card_number"
            type="text"
            label="شماره کارت"
            required="true"
            ref="cardNumber"
            pattern="card_number"
            min-length="16"
          />
          <GrayInput
            v-model="shaba_number"
            type="text"
            label="شماره شبا(IR)"
            required="true"
            ref="shabaNumber"
            pattern="shaba_card"
            min-length="24"
          />
        </div>
        <div class="button-section">
          <BlueBtn title="ثبت کارت" style="margin: auto" :onClick="submit" />
        </div>
      </div>
      <div class="table-container">
        <Table
          :headers="table_headers"
          :items="bank_accounts"
          :onclick="deleteItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import Table from "@/components/Table/Table";
import GrayInput from "@/components/input/GrayInput";
import BlueBtn from "@/components/Button/BlueBtn";
// import BankCardDropDownGrayInput from "@/components/input/BankCardDropDownGrayInput";
import BankCardSelectWithImage from "@/components/input/BankCardSelectWithImage";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: {
    // BankCardDropDownGrayInput,
    BlueBtn,
    GrayInput,
    Table,
    PanelHeader,
    Loading,
    BankCardSelectWithImage
  },
  data() {
    return {
      card_number: "",
      shaba_number: "",
      bank_id: "",
      bank_accounts: [],
      table_headers: [
        {
          id: 1,
          title: "شماره کارت"
        },
        {
          id: 2,
          title: "شماره حساب"
        },
        {
          id: 3,
          title: "شماره شبا"
        },
        {
          id: 4,
          title: "وضعیت"
        },
        {
          id: 5,
          title: "حذف"
        }
      ],
      options: []
    };
  },
  mounted() {
    this.getUserAccounts();
    this.getBanks();
  },
  methods: {
    handlerValue(value) {
      this.bank = value;
    },
    submit() {
      // this.$refs.dropdown.validateForm();
      // if (this.$refs.dropdown.errorMassage) {
      //   this.$refs.dropdown.focus();
      //   return false;
      // }

      this.$refs.cardNumber.validateForm();
      if (this.$refs.cardNumber.errorMassage) {
        this.$refs.cardNumber.focus();
        return false;
      }

      this.$refs.shabaNumber.validateForm();
      if (this.$refs.shabaNumber.errorMassage) {
        this.$refs.shabaNumber.focus();
        return false;
      }

      let data = {
        bank_id: this.bank_id,
        card_number: this.card_number,
        shaba_number: this.shaba_number
      };

      let loader = this.$loading.show();
      axios({
        url: "/panel/bank-account",
        data: data,
        method: "POST"
      })
        .then(response => {
          if (!response.data.status) {
            loader.hide();
            this.$toast.warning(response.data.message);
            return false;
          }
          this.getUserAccounts();
          loader.hide();
          this.$toast.success(response.data.message);
          this.card_number = "";
          this.shaba_number = "";
          this.bank = "";
        })
        .catch(error => {
          loader.hide();
          this.$toast.error(error.response.data.errors);
          console.log(error.response);
        });
    },
    getUserAccounts() {
      let loader = this.$loading.show();
      axios("panel/bank-account")
        .then(response => {
          loader.hide();
          this.bank_accounts = response.data;
        })
        .catch(error => {
          loader.hide();
          console.log(error.response);
        });
    },
    getBanks() {
      let loader = this.$loading.show();
      axios("bank")
        .then(response => {
          loader.hide();
          this.options = response.data;
        })
        .catch(error => {
          loader.hide();
          console.log(error);
        });
    },
    deleteItem(id) {
      this.$confirm(
        "آیا میخواهید این کارت را حذف کنید؟",
        "حذف کارت بانکی",
        "",
        {
          confirmButtonText: "بله",
          cancelButtonText: "خیر",
          icon: "question"
        }
      ).then(() => {
        let loader = this.$loading.show();
        axios({
          url: "/panel/bank-account/" + id,
          method: "DELETE"
        })
          .then(response => {
            if (!response.data.status) {
              this.getUserAccounts();
              loader.hide();
              this.$toast.error(response.data.message);
              return false;
            }
            this.getUserAccounts();
            loader.hide();
            this.$toast.success(response.data.message);
          })
          .catch(error => {
            loader.hide();
            console.log(error.response);
          });
      });
      return false;
    },
    getOptionItem(item) {
      if (item) this.bank_id = item.id;
    }
  }
};
</script>

<style scoped>
#bank-card {
  /*width: calc(100% - 250px);*/
  margin-right: auto;
  width: 100%;
}

.bank-card-container {
  padding: 0 10px;
  margin-top: 20px;
  height: 100vh;
  margin-bottom: 120px;
}

.add-card-form-container {
  width: 100%;
  min-height: 200px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  margin-top: 70px;
}

.table-container {
  margin-top: 20px;
}

.add-card-form__header {
  font-weight: 500;
  font-size: 16px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.add-card-form__wrapper {
  display: flex;
  gap: 80px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
  /*margin-top: 70px;*/
}

.button-section {
  margin: 30px auto;
}

@media (min-width: 960px) {
  #bank-card {
    /*width: calc(100% - 250px);*/
    margin-right: auto;
    width: 100%;
  }
}

@media (min-width: 1140px) {
  #bank-card {
    width: calc(100% - 250px);
  }

  .bank-card-container {
    padding: 0 40px;
  }
  .add-card-form-container {
    margin-top: 20px;
  }
}
</style>
