<template>
  <div
    class="bank-card-select-with-image-container"
    :class="{ fullWidth: fullWidth }"
  >
    <!--    <div class="custom-select" :tabindex="tabindex" @blur="open = false">-->
    <div class="title">{{ title || "نام بانک" }}</div>
    <div
      class="selected"
      :class="{ open: open, selectedWhite: selectedWhite }"
      @click="
        open = !open;
        openSelect = !openSelect;
      "
      ref="childRef"
    >
      <div class="card-selected-item">
        <img class="card-selected-image" :src="selectedImage" alt="" />
        <div class="card-selected-name">{{ selected }}</div>
        <div class="card-selected-card-number">
          {{ selectedCardNumber }}
        </div>
      </div>
      <img
        class="dropdown-icon"
        src="../../assets/images/dropDownIcon.svg"
        alt=""
        :class="{ openSelect: openSelect }"
      />
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        :key="item.id"
        class="item"
        v-for="item in modelOptions"
        @click="
          selected = item.name;
          selectedImage = item.image;
          selectedCardNumber = item.card_number;
          open = false;
          openSelect = !openSelect;
          $emit('input', item);
        "
      >
        <img class="option-image" :src="item.image" alt="" />
        <div class="option-name">{{ item.name }}</div>
        <div class="option-card-number">{{ item.card }}</div>
      </div>
    </div>
    <!--    option card-->
  </div>
</template>

<script>
export default {
  name: "BankCardSelectWithImage",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    propSelectedWhite: {},
    propFullWidth: {}
  },
  data() {
    return {
      open: false,
      selected: null,
      selectedImage: null,
      selectedCardNumber: null,
      modelOptions: this.options,
      openSelect: false,
      fullWidth: this.propFullWidth || false,
      selectedWhite: this.propSelectedWhite || false
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  watch: {
    options(newValue) {
      console.log(newValue);
      this.modelOptions = newValue;
    }
  }
};
</script>

<style scoped>
.bank-card-select-with-image-container {
  /*width: 100%;*/
  max-width: 280px;
  min-width: 280px;
  width: 100%;
  text-align: right;
  outline: none;
  direction: rtl;
  position: relative;
}

.title {
  color: #121212;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(31, 60, 136, 0.2);
  padding: 3px 15px;
  border-radius: 5px;
  min-height: 31px;
}

.card-selected-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.card-selected-image {
  width: 20px;
  transition: 0.3s all ease;
}

.card-selected-name {
  font-size: 14px;
}

.card-selected-card-number {
  font-size: 14px;
}

.items {
  margin-top: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  color: #121212;
  cursor: pointer;
  user-select: none;
}

.dropdown-icon {
  transition: 0.3s all ease;
}

.option-image {
  width: 20px;
}

.option-name {
  font-size: 14px;
}

.option-card-number {
  font-size: 14px;
}

.fullWidth {
  width: 100% !important;
  max-width: unset;
}

.selectedWhite {
  background-color: #ffff;
}

.selectHide {
  display: none;
}

.openSelect {
  transform: rotate(180deg);
}
</style>
