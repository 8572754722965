<template>
  <div class="button-container" @click="onClick">
    <div class="content">
      {{ title || "تغییر رمز عبور" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BlueBtn",
  props: {
    onClick: {
      type: Function,
      require: true
    },
    title: {
      type: String
    }
  }
};
</script>

<style scoped>
.button-container {
  width: 150px;
  background: #1f3c88;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
</style>
