<template>
  <label class="gray-input-container">
    <span class="label">{{ label }}</span>
    <input
      :type="type || 'text'"
      class="input"
      :class="[errorMassage ? 'error' : '']"
      :value="value"
      @input="handleInput($event.target.value)"
      :placeholder="placeholder"
      ref="childRef"
      :disabled="disabled"
      :icon="imageIcon"
      :pattern="pattern"
    />
    <img
      class="image-icon"
      src="../../assets/images/uil_calender.svg"
      alt=""
      v-if="imageIcon"
      width="20px"
    />
    <span class="error-massage">
      {{ errorMassage }}
    </span>
  </label>
</template>

<script>
export default {
  name: "GrayInput",
  props: [
    "label",
    "placeholder",
    "value",
    "type",
    "minLength",
    "required",
    "disabled",
    "pattern",
    "imageIcon"
  ],
  data() {
    return {
      validated: false,
      error: "",
      emailRegEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  computed: {
    errorMassage() {
      if (this.error) {
        return this.error;
      }
      if (!this.validated) {
        return "";
      }
      if (this.required && !this.value) {
        return "این فیلد الزامی است. لطفا آن را پر کنید";
      }

      if (this.minLength && this.value.length < this.minLength) {
        return `تعداد کارکترهای این فیلد باید بیشتر از ${this.minLength} باشد`;
      }

      return "";
    }
  },
  methods: {
    handleInput(value) {
      if (value && this.pattern === "fa") {
        if (/^[a-zA-Z0-9]+$/.test(value)) {
          this.error = "از عدد استفاده نکنید و زبان کیبورد باید فارسی باشد";
        } else {
          this.error = "";
        }
      }
      if (value && this.pattern === "email") {
        if (this.emailRegEx.test(value)) {
          this.error = "";
        } else {
          this.error = "ایمیل را به درستی وارد کنید ";
        }
      }
      if (value && this.pattern === "nc") {
        if (this.isValid(value)) {
          this.error = "";
        } else {
          this.error = "کد ملی را بدرستی وارد کنید";
        }
      }

      if (value && this.pattern === "number") {
        if (/^[0-9]+$/.test(value)) {
          this.error = "";
        } else {
          this.error = "لطفا شماره تلفن را به درستی وارد کنید";
        }
      }

      if (value && this.pattern === "shaba_number") {
        if (/^[0-9]+$/.test(value)) {
          this.error = "";
        } else {
          this.error = "لطفا شماره شبا را به درستی وارد کنید";
        }
      }

      if (value && this.pattern === "card_number") {
        if (/^[0-9]+$/.test(value)) {
          this.error = "";
        } else {
          this.error = "لطفا شماره کارت را به درستی وارد کنید";
        }
      }

      this.$emit("input", value);
      this.validated = true;
    },
    validateForm() {
      this.validated = true;
    },
    focus() {
      this.$refs.childRef.focus();
    },
    isValid(nationalCode) {
      if (!/^\d{10}$/.test(nationalCode)) return false;
      let check = parseInt(nationalCode[9]);
      let sum = 0;
      let i;
      for (i = 0; i < 9; ++i) {
        sum += parseInt(nationalCode[i]) * (10 - i);
      }
      sum %= 11;

      return (sum < 2 && check === sum) || (sum >= 2 && check + sum === 11);
    }
  }
};
</script>

<style scoped>
.gray-input-container {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  min-width: 280px;
  width: 100%;
}

.input:disabled {
  background: rgba(31, 60, 136, 0.1) !important;
}

.input {
  outline: none;
  border: none;
  background: rgba(31, 60, 136, 0.2);
  border-radius: 5px;
  padding: 4px 10px;
  color: rgba(18, 18, 18, 0.7);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
}

.label {
  color: #121212;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

.image-icon {
}

@media (max-width: 328px) {
  .gray-input-container {
    width: 100%;
    min-width: 100%;
  }
}
</style>
